export const downloadFilePDF = (url, filename = '') => {
  if (filename.length === 0) filename = url.split('/').pop();
  const req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'blob';
  req.onload = function () {
    const blob = new Blob([req.response], {
      type: 'application/pdf',
    });


    const contentDisposition = req.getResponseHeader('Content-Disposition')
    if (contentDisposition) {
      const utf8Filename = decodeURIComponent(escape(contentDisposition))
      const filenameWithExtensionMatch = utf8Filename.match(/filename="([^"]+)"/)
      const filenameWithExtension = filenameWithExtensionMatch ? filenameWithExtensionMatch[1] : ''

      const regex = /^(.+)\.([^.]+)$/

      const matches = filenameWithExtension.match(regex)

      if (matches) {
        filename = matches[0]
      }
    }

    const isIE = !!document.documentMode;
    if (isIE) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const windowUrl = window.URL || window.webkitURL;
      const href = windowUrl.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('download', filename);
      a.setAttribute('href', href);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  req.send();
};